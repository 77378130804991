import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import Share from '../../components/Share';
import ContactForm from '../../components/ContactForm';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const ArticleCard = makeShortcode("ArticleCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Row mdxType="Row">
      <Column colLg={4} colMd={4} mdxType="Column">
        <ArticleCard color='dark' title="Introducing new price action trading strategy" subTitle="Wizdough Second Entry Strategy for NinjaTrader 8" author="Wizdough" date="July 28, 2022" href="/blog/second-entry-strategy" mdxType="ArticleCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "879px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "66.31944444444444%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACtUlEQVQ4yz2TSZPSUBSFWWozZIIwJB0ImQmQBAgkAaqaqbQt0QarVy60yqVV7ty5sJwW/unjfY/Gxa173vTl3PduSmk6x2QyRTKOMY0TxFGMJJkgJh2RjqMEo1EETdPQ6XR41jWdZxaqqvIwjC6tayiZpgnHdREaBoakbctCv9+H1WfZgkXjbrdLBwwsFgtMp1M4jsP3sMzWWc6yDOPRmIC9HmyaGOg6AgKyjewjl+jzMQMyF2yu3W7j5uYGlUoFtVoNgiBwLQgiJEm6OLSZQwa0bZhPDq/BHOhsLQjw8PCA/X6P0+nEnTKwoigcXC6zDwgo9cihRaCAXPiUe//dmeBrBNR0DYEfYLfbceDhcMB2u+VXsF6vsVqtcT69Q54XKLHa2WSW58iLAsvVim/MabxcLmk+wySaoqC145vXeHx8xPl8wvF4JKdn3N+/5Pc3Go7huh5Knu8jnc+5E/9Js1LDMMRsnqKn9zHKPQz2tzBDDbbpYkyHja4Bb+DAsR00m01Iokx3KBPQ8zBLU162H/hcM+BgEGKSzDAoTGy+0X0eRNSD51A0AXWrCsV7Bskoo1qu8UeRZQXyFXh16HkEnBHQIofDEIvdBHffDex+0eNsFGjzKrpLEb21hNtlFaopQqxJEOl1VbWFutJgJXv0YnPed8HIp/tKMXvfQ/HBx+7rAJvft9h+J4dbGZ20AqMQYOQitLyCBgFZqdVqlQdroVLx0UZWTLH4ZGL12cbmS4jtn4urF39d3P0g/fMJOCfgkoCFCL24AGVJ4f3XbLbRaDRRitcEWSUY3JmItg6Ktwm8rIto4yF7FcPLDcSkNbsJQS1DNWQ0DQViqwJREaA2VN7UrZbOyy45ls//VavnwiU9DCJ4doDADUmPad6B5wR0sAWhJkKR66jLDa5FAoniNSRq8jr+AcL8e0PZRRTDAAAAAElFTkSuQmCC')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "thumb1",
              "title": "thumb1",
              "src": "/static/54f24c37a82e9ca8859eebc71e8aaca5/ddbf1/thumb1.png",
              "srcSet": ["/static/54f24c37a82e9ca8859eebc71e8aaca5/7fc1e/thumb1.png 288w", "/static/54f24c37a82e9ca8859eebc71e8aaca5/a5df1/thumb1.png 576w", "/static/54f24c37a82e9ca8859eebc71e8aaca5/ddbf1/thumb1.png 879w"],
              "sizes": "(max-width: 879px) 100vw, 879px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span>
        </ArticleCard>
      </Column>
      <Column colLg={4} colMd={4} mdxType="Column">
        <ArticleCard color='dark' title="NinjaTrader 8 Alert Sounds" subTitle="How to Change Annoying Buzzing Alerts to Nice Voiceover" author="Wizdough" date="February 12, 2021" href="/blog/better-sounds" mdxType="ArticleCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "879px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "66.31944444444444%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACy0lEQVQ4y12Sz24SURTG584MwzAwDMPwTwqFthQstEhBYEqg2Eoxpa0pTbVpTBcmpvafMSZG48IXMC6ML6GJ6cZo4gO4c+nL2NXnuXfoQie5c+7czPnu7zvnSEyNgimWWLIvTjECiQXh05IUTfh1B2UzD5VpMMwYwtEM7EQOYScDM5qGYaVgxbLwBR3Iug1JkjQwSaWNApmLyiF+iHx2G0YgLfaSbGAqO4N6w0WhWMFSrQW3exe36h002qsolutCeCKoe0mSTMvEv98KLRXDwQ7cZheNehvVagNTmTysSBIWEdrxrHAkMQM+w6GohmBOLyJAyLF4HlGyYkdicJwkDCMKO5bGh/cfsTXYxv7+IVZW+kTaRqGwQMKzmCmUMT1XwWyxCqZZkNSAg1xjDMfOodXuo9nsoV5vYbCxiUymhNmbVXz5fImD3QMcPXqM0WgXe3sP0Wx1sLm5g9HWLhqtHpbJgaSa/1s2wGvq7f3ivLRUw/dvP3A4PsTZyTNsb43hul0MhyP0Vtfhdvp4cHCEWCrvCTLyzqiDjPmpKTauvxWFizPMlWr4dPkVo3v3sdYfUh1dJFNZzBfLqCzWMF9aQnW5jYiTnhCyAL18goipMepoSOxlDx/j/SP8/PUbpUodmp8T+Cf/S5PFPEekI2thypeDCPjDNC78IEFkNhSi9PuiIuHi+Wvw5+npGbrrAyQSZE0JkhsdTNYoGnR5iGCC4A2WVFLtLCwiEXYQNiNwAibSuoVcOAWVbj6+eOkJnp3D7d2BZd0Qc+mR0vwyLkxlUoJClCACVDNd1FBEWRcJKh8BIjy5Fjy/oAGukW1ywBOZ5s0pjzyHN5GXj4ka6pPu+r1akrDMKbjlF2+E4JOTU7S6fRro9CRxIigWoxlOIWTaniAXEBYEqSEu0Gk+y7c38OrtO/y5usIxEbqra4gn8x6AILtuqAJVJQhFw19pOGOD+21uswAAAABJRU5ErkJggg==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "image1",
              "title": "image1",
              "src": "/static/3283576293c030a4b32ed5467f089397/ddbf1/image1.png",
              "srcSet": ["/static/3283576293c030a4b32ed5467f089397/7fc1e/image1.png 288w", "/static/3283576293c030a4b32ed5467f089397/a5df1/image1.png 576w", "/static/3283576293c030a4b32ed5467f089397/ddbf1/image1.png 879w"],
              "sizes": "(max-width: 879px) 100vw, 879px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span>
        </ArticleCard>
      </Column>
    </Row>
    <Row mdxType="Row">
      <Column mdxType="Column">
        <hr></hr>
        <br /><br />
      </Column>
      <Column colMd={3} colLg={6} className="bx--type-expressive-heading-03" mdxType="Column">
        <p>{`Do you need technology or a new concept for the business? `}</p>
        <p>{`Contact us and we’ll help you plan your route. Our team will contact you for more information about your requirements and a detailed analysis of your needs. `}</p>
        <p>{`We will use your information to make a detailed evaluation proposal available to you.`}</p>
      </Column>
      <Column colMd={3} colLg={5} offsetMd={1} offsetLg={1} mdxType="Column">
Please fill out the form below and let us talk to you about your idea. You will be kept confidential with your details.
        <br />
        <ContactForm mdxType="ContactForm" />
      </Column>
    </Row>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      